import React, { useState, useEffect, useRef } from "react";
import { Accordion, Col, Row, Table } from "react-bootstrap";
import {
  postSaveSingleFieldOfViewUpdateOfApplicationManager,
  postUpdateStageStatusOfDataViewUpdateOfApplicationManager,
} from "../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Widgets/Loader/Loader";
import VerificationModal from "./VerificationModal/VerificationModal";

const Stage1 = ({ ApiPageData, pointerID, getAllDataOfViewUpdateFunction ,getAllDataOfViewUpdateFunctionAfterAutoSave}) => {
  const stage = "stage_1";
  const [isSelectionMade, setIsSelectionMade] = useState(false);

  const [loading, setLoading] = useState(false);

  const [extraData, setExtraData] = useState({});
  const [isExtraDataEditing, setIsExtraDataEditing] = useState({});

  const [BigTextAreaData, setBigTextAreaData] = useState({});
  const [isBigTextAreaEditing, setIsBigTextAreaEditing] = useState({});

  const [teamMember, setTeamMember] = useState({});
  const [isTeamMemberEditing, setIsTeamMemberEditing] = useState({});

  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState("");

  const [isFileChoosen, setIsFileChoosen] = useState({});
  const [isFileEditing, setIsFileEditing] = useState({});
  const [fileState, setFileState] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const fileInputRefs = useRef({});


  const [IsTeamMemberSelected, setIsTeamMemberSelected] = useState(false);

  const [IsTeamMemberParam, setIsTeamMemberParam] = useState(null);
  const [IsTicketNumberValue, setIsTicketNumberValue] = useState(null);




  const [IsTicketNumberEntered, setIsTicketNumberEntered] = useState(false);



  const [currentFields, setCurrentFields] = useState([]);

  const [inputKey, setInputKey] = useState(Date.now());

  useEffect(() => {
    if (ApiPageData) {
      // Setting initial data based on selected options
      const initialBigTextAreaData = {};
      const initialExtraData = {};
      const initialTeamMember = {};
      const initialEditingState = {};
  
      ApiPageData.selected_options.fields.forEach((field) => {
        if (field.type === "textarea") {
          initialBigTextAreaData[field.param] = field.db_value;
          initialEditingState[field.param] = false; 
        } else if (field.type === "select") {
          initialTeamMember[field.param] = field.db_value;
          initialEditingState[field.param] = false;
        } else if (["text", "date", "number"].includes(field.type)) {
          initialExtraData[field.param] = field.db_value;
          initialEditingState[field.param] = false; 
        } else if (field.type === "file") {
          initialEditingState[field.param] = false;
        }
        // Add other field types as needed
      });
  
      // Update the state
      setBigTextAreaData(initialBigTextAreaData);
      setExtraData(initialExtraData);
      setTeamMember(initialTeamMember);
      setIsExtraDataEditing(initialEditingState);
      setIsBigTextAreaEditing(initialEditingState);
      setIsTeamMemberEditing(initialEditingState);
      setIsFileEditing(initialEditingState);
    }
  }, [ApiPageData]);

  
  useEffect(() => {
    if (ApiPageData) {
      setStatusOptions(ApiPageData?.options || []);
      //   setSelectedStatusValue(ApiPageData?.status || "");
      const currentStatusOption = ApiPageData?.options?.find(
        (opt) => opt?.option === ApiPageData?.status
      );
      setCurrentFields(currentStatusOption?.fields || []);
    }
  }, [ApiPageData]);

  useEffect(() => {
    const currentStatusOption = statusOptions.find(
      (opt) => opt?.option === selectedStatusValue
    );
    setCurrentFields(currentStatusOption?.fields || []);
  }, [selectedStatusValue, statusOptions]);

  useEffect(() => {
    setSelectedStatusValue(ApiPageData?.status);
    setIsSelectionMade(false);
  }, [ApiPageData]);



  
  const callGetFunction = async () => {

    getAllDataOfViewUpdateFunctionAfterAutoSave();

  };




  const saveSingleFileDataFunction = async (formData,param) => {
    try {
      setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);
        // callGetFunction();
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {

        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );

           getAllDataOfViewUpdateFunction(pointerID);

           
           const isEnteringEditMode = !isFileEditing[param];
           setIsFileEditing((prevEditing) => ({
             ...prevEditing,
             [param]: isEnteringEditMode,
           }));

           setIsTeamMemberSelected(false)
           setIsTicketNumberEntered(false);
           setInputKey(Date.now()); 

      } else {
        setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving single data:", error);
    }

  };







  const saveSingleDataFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);
        // callGetFunction();
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );


        getAllDataOfViewUpdateFunction(pointerID);
        setIsTeamMemberSelected(false)
        setIsTicketNumberEntered(false);
        setInputKey(Date.now()); 
          // if (isSelectionMade && statusOptions.some((opt) => opt?.option === selectedStatusValue)) {
          //   getAllDataOfViewUpdateFunction(pointerID);
          // }
        
        



      } else {
        setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving single data:", error);
    }

  };

  const autoSaveSingleDataFunction = async (formData) => {
    try {
      // setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);

      // setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        
        // toast.success(
        //   response?.data?.response?.success_msg || "Saved Successfully."
        // );
        setInputKey(Date.now()); 

        // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);
        // getAllDataOfViewUpdateFunction(pointerID);
        
        //  console.log("saved")

      } else {
        // setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        // toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error saving single data:", error);
    }
  };






  const UpdateStageStatusFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postUpdateStageStatusOfDataViewUpdateOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // setSelectedStatusValue("");
        toast.success(
          response?.data?.response?.success_msg || "Stage Updated Successfully."
        );
        getAllDataOfViewUpdateFunction(pointerID);
        setCancelRequestShow(false);
        setIsSelectionMade(false);
        setInputKey(Date.now()); 
        setIsTeamMemberSelected(false)
        setIsTicketNumberEntered(false);
      } else {
        setLoading(false);
        console.error(
          "Error updating stage status data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error updating stage status data:", error);
    }
  };

  const handleChangeStatus = (event) => {
    setIsSelectionMade(true);

    const newStatus = event.target.value;

    // Clear all states when status changes
    if (newStatus !== selectedStatusValue) {
      setExtraData({});
      setFileState({});
      setFileErrors({});
      setTeamMember({});
      setIsFileEditing({});
      setIsExtraDataEditing({});
      setIsTeamMemberEditing({});
      setBigTextAreaData({});
      setIsBigTextAreaEditing({});
    }

    setSelectedStatusValue(newStatus);
  };




  const handleUpdate = () => {
    if (selectedStatusValue === "") {
      toast.warning("Please select status");
      return;
    }

    const formData = new FormData();
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);
    formData.append("id", ApiPageData?.id);
    const paramForSoumyaArray = [];

    // Iterate through all entries
    [
      ...Object.entries(extraData),
      ...Object.entries(BigTextAreaData),
      ...Object.entries(fileState),
      ...Object.entries(teamMember),
    ].forEach(([param, value]) => {
      if (currentFields.some((field) => field.param === param)) {

        if(value === null){
          value = "";
        }

        // if (value) {
          if (value instanceof File) {
            // If the value is a File, append it directly to formData
            formData.append(param, value);
            
            // Push the param with its type as "file"
            paramForSoumyaArray.push({ param, value: "File Object", type: "file" });
          } else if (typeof value === 'object' && !(value instanceof File)) {
            // For objects or other complex data (but not files), stringify them
            formData.append(param, JSON.stringify(value));
            
            // Push the param with its type as "not file"
            paramForSoumyaArray.push({ param, value: JSON.stringify(value), type: "not file" });
          } else {
            // For simple string or text values, append them directly
            formData.append(param, value);
            
            // Push the param with its type as "not file"
            paramForSoumyaArray.push({ param, value, type: "not file" });
          }
        // }
      }
    });
    
    // Append the array to formData under the key "paramforsoumya" for backend reference
    formData.append('param', JSON.stringify(paramForSoumyaArray));
    

    UpdateStageStatusFunction(formData);
  };





// Common debounce function
const debounce = (callback, delay = 2000) => {

  let timeout;
  
  // Return a function that handles debouncing
  return (...args) => {
    // Clear the previous timeout
    clearTimeout(timeout);

    // Set a new timeout to execute the callback after the delay
    timeout = setTimeout(() => {
      callback(...args); // Call the provided callback with arguments
    }, delay);
  };
};

















  // const handleChangeAQATO_TeamMember = (e, param) => {
  //   setTeamMember((prevData) => ({
  //     ...prevData,
  //     [param]: e.target.value,
  //   }));
  //   setIsTeamMemberEditing((prevEditing) => ({
  //     ...prevEditing,
  //     [param]: true,
  //   }));

  //   const formData = new FormData();
  //   formData.append("param", param);
  //   formData.append("value", e.target.value);
  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //     // auto  //   saveSingleDataFunction(formData);

  // };

  // const toggleTeamMemberEditing = (param) => {
  //   setIsTeamMemberEditing((prevEditing) => ({
  //     ...prevEditing,
  //     [param]: !prevEditing[param],
  //   }));

  //   if (isTeamMemberEditing[param]) {
  //     saveTeamMember(param);
  //   }
  // };

  // const saveTeamMember = (param) => {
  //   // console.log(
  //   //   `Saving team member for field: ${param}, Value:`,
  //   //   teamMember[param]
  //   // );

  //   const formData = new FormData();

  //   formData.append("param", param);
  //   formData.append("value", teamMember[param]);

  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //     //   saveSingleDataFunction(formData);
  //   setTeamMember((prevState) => ({ ...prevState, [param]: "" }));
  // };





// Handle changes in the team member field and trigger autosave
const handleChangeAQATO_TeamMember = (e, param) => {
  
  const newValue = e.target.value;

  // Update the teamMember state with the new value
  setTeamMember((prevData) => ({
    ...prevData,
    [param]: newValue,
  }));


if(param === "change_attc_team_member"){
  setIsTeamMemberSelected(true);
  setIsTicketNumberValue(newValue);
  setIsTeamMemberParam(param);
}else{
  setIsTeamMemberSelected(false);
  setIsTicketNumberValue(null);
  setIsTeamMemberParam(null);
}

  const currentField = currentFields.find((field) => field.param === param);

  setIsTeamMemberEditing((prevEditing) => ({
    ...prevEditing,
    [param]: newValue !== currentField?.db_value, 
  }));




  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);


if(param !== "change_attc_team_member"){
  // handleSaveAttcTeamMemberMember(param,newValue);
  setIsTicketNumberValue(newValue);
  setIsTeamMemberParam(param);
} else{
   // Prepare form data for autosave
   const formData = new FormData();
   formData.append("param", param);
   formData.append("value", newValue);
   formData.append("status", selectedStatusValue);
   formData.append("stage", stage);
   formData.append("pointer_id", pointerID);
}


  //   // auto  //   saveSingleDataFunction(formData);


  // const debouncedAutoSave = debounce(autoSaveSingleDataFunction, 2000);
  // debouncedAutoSave(formData);


  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

};








// Handle changes in the team member field and trigger autosave
const handleSaveAttcTeamMemberMember = (param,newValue) => {
  
  console.log(param,"paramparamparam")
  console.log(newValue,"newValuenewValuenewValue")

 
  // Prepare form data for autosave
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", newValue);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  saveSingleDataFunction(formData);


};







const toggleTeamMemberEditing = (param) => {
  setIsTeamMemberEditing((prevEditing) => ({
    ...prevEditing,
    [param]: !prevEditing[param], // Toggle the current state
  }));

  // If the field is being edited and is about to be saved, save the data
  if (isTeamMemberEditing[param]) {
    saveTeamMember(param);
  }
};


// Save the team member data to the backend
const saveTeamMember = (param) => {
  // Log the data being saved for debugging
  console.log(`Saving team member data for field: ${param}, Value:`, teamMember[param]);

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Prepare form data for saving
  const formData = new FormData();
  formData.append("param", param);
  // formData.append("value", teamMember[param]);
  formData.append(
    "value",
    teamMember[param] === undefined || teamMember[param] === null || teamMember[param] === "undefined" || teamMember[param] === "null"
      ? ""
      : teamMember[param]
  );
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  saveSingleDataFunction(formData);

  // Optionally reset the value to db_value after saving
   // setTeamMember((prevState) => ({
  //   ...prevState,
  //   [param]: currentField?.db_value, // Reset to db_value after save
  // }));
};















































// Modify handleFileChange
const handleFileChange = (e, param) => {
  const file = e.target.files[0];

  if (file && file.type !== "application/pdf") {
    setIsFileChoosen((prev) => ({ ...prev, [param]: false }));
    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [param]: "Please upload a PDF file.",
    }));
    toast.error("Please upload a PDF file.");
    setFileState((prevState) => ({ ...prevState, [param]: null }));
    fileInputRefs.current[param].value = null;
  } else {
    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [param]: "",
    }));
    setFileState((prevState) => ({ ...prevState, [param]: file }));
    setIsFileChoosen((prev) => ({ ...prev, [param]: true }));
    // setIsFileEditing((prevEditing) => ({
    //   ...prevEditing,
    //   [param]: true,
    // }));
  }
};


const toggleFileEditing = (param) => {
  const isEnteringEditMode = !isFileEditing[param];
  setIsFileEditing((prevEditing) => ({
    ...prevEditing,
    [param]: isEnteringEditMode,
  }));

  // if (!isEnteringEditMode) {
  //   saveFile(param);
  // }
};

const saveFile = (param) => {
  if (!fileState[param]) {
    toast.error("Please select a PDF file.");
    return;
  }

  console.log(`Saving file for field: ${param}, File:`, fileState[param]);

  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", fileState[param]);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  saveSingleFileDataFunction(formData,param);

  setFileState((prevState) => ({ ...prevState, [param]: null }));
  fileInputRefs.current[param].value = null;

};





















  // const handleExtraDataChange = (e, param) => {
  //   setExtraData((prevData) => ({
  //     ...prevData,
  //     [param]: e.target.value,
  //   }));

  //   const formData = new FormData();
  //   formData.append("param", param);
  //   formData.append("value", e.target.value);
  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //     // auto  //   saveSingleDataFunction(formData);

  // };

  // const toggleExtraDataEditing = (param) => {
  //   setIsExtraDataEditing((prevEditing) => ({
  //     ...prevEditing,
  //     [param]: !prevEditing[param],
  //   }));

  //   if (isExtraDataEditing[param]) {
  //     saveExtraData(param);
  //   }
  // };

  // const saveExtraData = (param) => {
  //   console.log(
  //     `Saving extra data for field: ${param}, Value:`,
  //     extraData[param]
  //   );

  //   const formData = new FormData();

  //   formData.append("param", param);
  //   formData.append("value", extraData[param]);

  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //     //   saveSingleDataFunction(formData);
  //   setExtraData((prevState) => ({ ...prevState, [param]: "" }));
  // };







  // Handle changes in the extra data field and trigger autosave
const handleExtraDataChange = (e, param) => {
  const newValue = e.target.value;

  // Update the extraData state with the new value
  setExtraData((prevData) => ({
    ...prevData,
    [param]: newValue,
  }));

if(param === "change_unique_no"){setIsTicketNumberEntered(true)}else{setIsTicketNumberEntered(false)}

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Show the save button if the new value differs from the db_value
  setIsExtraDataEditing((prevEditing) => ({
    ...prevEditing,
    [param]: newValue !== currentField?.db_value, // Show if values differ
  }));

  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

  // Prepare form data for autosave
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", newValue);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);


    // auto  //   saveSingleDataFunction(formData);


  // const debouncedAutoSave = debounce(autoSaveSingleDataFunction, 2000);
  // debouncedAutoSave(formData);

  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

};

// Toggle editing state and save the extra data if needed
const toggleExtraDataEditing = (param) => {
  // Toggle the edit mode for the specific field
  setIsExtraDataEditing((prevEditing) => ({
    ...prevEditing,
    [param]: !prevEditing[param], // Toggle the current state
  }));


  if(param !== "change_unique_no"){
  if (isExtraDataEditing[param]) {
    saveExtraData(param);
  }}

};





// Save the extra data to the backend
const saveTicket = (param) => {


  // if (extraData[param] === "") {
  //   toast.error("Please enter a valid ticket number.");
  //   return;
  // }

  if (!IsTeamMemberSelected) {
    toast.error("Please select an ATTC Team Member.");
    return;
  }


  handleSaveAttcTeamMemberMember(IsTeamMemberParam,IsTicketNumberValue);



  // Log the data being saved for debugging
  console.log(`Saving extra data for field: ${param}, Value:`, extraData[param]);

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  const currentValue = (extraData[param] === undefined || extraData[param] === "undefined") ? "" : extraData[param];



  // Prepare form data for saving
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", extraData[param]);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  // Call the save function
      saveSingleDataFunction(formData);

      getAllDataOfViewUpdateFunction(pointerID);



  // Optionally reset the value to db_value after saving
  // setExtraData((prevState) => ({
  //   ...prevState,
  //   [param]: currentField?.db_value, // Reset to db_value after save
  // }));
};




// Save the extra data to the backend
const saveExtraData = (param) => {
  // Log the data being saved for debugging
  console.log(`Saving extra data for field: ${param}, Value:`, extraData[param]);

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Prepare form data for saving
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", extraData[param]);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  // Call the save function
      saveSingleDataFunction(formData);

  // Optionally reset the value to db_value after saving
  // setExtraData((prevState) => ({
  //   ...prevState,
  //   [param]: currentField?.db_value, // Reset to db_value after save
  // }));
};



































// Handle changes in the text area and trigger autosave
const handleBigTextAreaDataChange = (e, param) => {
  const newValue = e.target.value;

  // Update the BigTextAreaData state with the new value
  setBigTextAreaData((prevData) => ({
    ...prevData,
    [param]: newValue,
  }));

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Update editing status only if there's a change
  setIsBigTextAreaEditing((prevEditing) => ({
    ...prevEditing,
    [param]: newValue !== currentField?.db_value, 
  }));

  // Autosave after delay
  // debounceAutoSave(param, newValue);
};

// Debounced autosave function
const debounceAutoSave = debounce((param, value) => {
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", value);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  // Trigger the autosave function
  // saveSingleDataFunction(formData);
}, 2000); // Autosave delay in milliseconds

// Toggle the save button and save the data
const toggleBigTextAreaDataEditing = (param) => {
  // Save data if field is in edit mode
  if (isBigTextAreaEditing[param]) {
    saveBigTextAreaData(param);
  }

  // Toggle edit mode
  setIsBigTextAreaEditing((prevEditing) => ({
    ...prevEditing,
    [param]: !prevEditing[param],
  }));
};

// Save the data to the backend
const saveBigTextAreaData = (param) => {
  console.log(`Saving big text area data for field: ${param}, Value:`, BigTextAreaData[param]);

  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", BigTextAreaData[param]);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  // Call the save function
  // saveSingleDataFunction(formData);
};















  const [showCancelRequestModal, setCancelRequestShow] = useState(false);
  const handleCancelRequestClose = () => setCancelRequestShow(false);
  const handleCancelRequestShow = () => {
    setCancelRequestShow(true);
  };

  return (
    <>
      <Accordion.Item eventKey="1">
        <Accordion.Header className="accordion_header">
          Stage 1 - Self Assessment
        </Accordion.Header>

        {loading && <Loader />}

        <Accordion.Body>
          <Table striped>
            <tbody>
              {ApiPageData?.status && (
                <tr style={{ cursor: "default" }}>
                  <td>
                    <b style={{ color: "#055837" }}>Current Status</b>
                  </td>
                  <td className="w-25">
                    <b style={{ color: "#055837" }}>{ApiPageData?.status}</b>
                  </td>
                </tr>
              )}

              {ApiPageData?.submitted_date !== "" && (
                <tr>
                  <td width="30%">Date Submitted</td>
                  <td className="w-25">{ApiPageData?.submitted_date}</td>
                </tr>
              )}

              {ApiPageData?.lodged_date !== "" && (
                <tr>
                  <td width="30%">Date Loged</td>
                  <td className="w-25">{ApiPageData?.lodged_date}</td>
                </tr>
              )}

              {ApiPageData?.in_progress_date !== "" && (
                <tr>
                  <td width="30%">Date In Progress</td>
                  <td className="w-25">{ApiPageData?.in_progress_date}</td>
                </tr>
              )}

              {ApiPageData?.approved_date !== "" && (
                <tr>
                  <td width="30%">Date Approved</td>
                  <td className="w-25">{ApiPageData?.approved_date}</td>
                </tr>
              )}

              {ApiPageData?.omitted_date !== "" && (
                <tr>
                  <td width="30%"> Date Omitted</td>
                  <td className="w-25">{ApiPageData?.omitted_date}</td>
                </tr>
              )}

              {ApiPageData?.withdraw_date !== "" && (
                <tr>
                  <td width="30%"> Date Withdraw</td>
                  <td className="w-25">{ApiPageData?.withdraw_date}</td>
                </tr>
              )}

              {ApiPageData?.declined_date !== "" && (
                <tr>
                  <td width="30%"> Date Declined</td>
                  <td className="w-25">{ApiPageData?.declined_date}</td>
                </tr>
              )}

              {/* {ApiPageData?.archive_date !== "" && (
                <tr>
                  <td width="30%"> Date Archive</td>
                  <td className="w-25">{ApiPageData?.archive_date}</td>
                </tr>
              )} */}


              { ApiPageData?.expiry_custom_date?.label !== "Date Closed" && ApiPageData?.expiry_custom_date &&
                !Array.isArray(ApiPageData.expiry_custom_date) &&
                ApiPageData?.expiry_custom_date?.label !== "" &&
                ApiPageData?.expiry_custom_date?.value !== "" && (
                  <tr>
                    <td width="30%">
                      {ApiPageData?.expiry_custom_date?.label}
                    </td>
                    <td className="w-25">
                      {ApiPageData?.expiry_custom_date?.value}
                    </td>
                  </tr>
                )}

              {/* {ApiPageData?.expiry_date !== "" && (
                <tr>
                  <td width="30%">Expiry Date</td>
                  <td className="w-25">{ApiPageData?.expiry_date}</td>
                </tr>
              )}

              {ApiPageData?.closure_date !== "" && (
                <tr>
                  <td width="30%">Closure Date</td>
                  <td className="w-25">{ApiPageData?.closure_date}</td>
                </tr>
              )} */}

              {ApiPageData?.date_reinstate !== "" && (
                <tr>
                  <td width="30%">Date Reinstate</td>
                  <td className="w-25">{ApiPageData?.date_reinstate}</td>
                </tr>
              )}

              {ApiPageData?.lodged_date_additional !== "" && (
                <tr>
                  <td width="30%"> Date Additional Info Lodged</td>
                  <td className="w-25">
                    {ApiPageData?.lodged_date_additional}
                  </td>
                </tr>
              )}

              {ApiPageData?.lodged_comment !== "" && (
                <tr>
                  <td width="30%">Loged Comment</td>
                  <td className="w-25">{ApiPageData?.lodged_comment}</td>
                </tr>
              )}

              {ApiPageData?.approved_comment !== "" && (
                <tr>
                  <td width="30%">Approved Comment</td>
                  <td className="w-25">{ApiPageData?.approved_comment}</td>
                </tr>
              )}

              {ApiPageData?.declined_reason !== "" && (
                <tr>
                  <td width="30%">Declined Reason</td>
                  <td className="w-25">{ApiPageData?.declined_reason}</td>
                </tr>
              )}




{ ApiPageData?.expiry_custom_date?.label === "Date Closed" && ApiPageData?.expiry_custom_date &&
                !Array.isArray(ApiPageData.expiry_custom_date) &&
                ApiPageData?.expiry_custom_date?.label !== "" &&
                ApiPageData?.expiry_custom_date?.value !== "" && (
                  <tr>
                    <td width="30%">
                      {ApiPageData?.expiry_custom_date?.label}
                    </td>
                    <td className="w-25">
                      {ApiPageData?.expiry_custom_date?.value}
                    </td>
                  </tr>
                )}



              <tr>
                <td width="30%">Change Status</td>
                <td className="w-25">
                  <Row>





                    
                    <Col lg={8}>
                    <select
  className="form-select"
  value={
    isSelectionMade && statusOptions.some((opt) => opt?.option === selectedStatusValue)
      ? selectedStatusValue
      : ""
  }
  onChange={(e) => handleChangeStatus(e)}
  style={{ cursor: "pointer" }}
>
  <option value="" disabled>
    Select Status
  </option>
  {statusOptions.map((opt) => (
    <option
      key={opt?.option}
      value={opt?.option}
      disabled={opt?.is_enabled === 0}
    >
      {opt?.option}
    </option>
  ))}
</select>

                    </Col>
                  </Row>
                </td>
              </tr>



























































              {
                ApiPageData?.selected_options?.fields.length > 0
                  ? ApiPageData?.selected_options?.fields.map((field) => (
                      <React.Fragment key={field?.param}>
                        {field?.type === "select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) =>
                                      handleChangeAQATO_TeamMember(
                                        e,
                                        field?.param
                                      )
                                    }
                                    className="form-select"
                                    // disabled={
                                    //   !isTeamMemberEditing[field?.param]
                                    // }
                                    disabled={
                                      field?.param !== "change_attc_team_member"
                                        ? !isTeamMemberEditing[field?.param]
                                        : false
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="" disabled>
                                      Select Team Member
                                    </option>
                                    {field.options?.map((member) => (
                                      <option
                                        key={member.id}
                                        value={member.id}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {member.fullname}
                                      </option>
                                    ))}
                                  </select>
                                </Col>

                                <Col>
                                {(field?.param !== "change_attc_team_member") && (
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> 
                                )}


{/* {(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}



                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}





{field.type === "textarea" && (
  <tr>
    <td>{field?.label}</td>
    <td className="w-25">
      <Row>
        <Col lg={8}>
          <textarea
            className="form-control"
            rows="3"
            value={BigTextAreaData[field?.param] ?? field?.db_value}
            onChange={(e) => handleBigTextAreaDataChange(e, field?.param)}
            disabled={!isBigTextAreaEditing[field?.param]}
          />
        </Col>
        <Col lg={4} className="my-auto">
          <button
            className="btn btn-sm btn_green_yellow"
            title={isBigTextAreaEditing[field?.param] ? "Save" : "Edit"}
            onClick={() => toggleBigTextAreaDataEditing(field?.param)}
          >
            <i
              className={
                isBigTextAreaEditing[field?.param]
                  ? "bi bi-check-lg"
                  : "bi bi-pencil-square"
              }
            ></i>
          </button>
        </Col>
      </Row>
    </td>
  </tr>
)}













{field?.type === "file" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>

                                
                                {(field?.db_value?.document_name !== "" &&
                                  field?.db_value?.document_fullpath !== "" && field?.db_value !== "" )&& (



                                    <div
                                    className="d-flex align-items-center"
                                    style={{
                                      width: "100%", // Ensures the parent div takes full width
                                      justifyContent: "space-between", // Adjusts spacing between child elements
                                      flexWrap: "wrap", // Ensures responsive layout for smaller screens
                                    }}
                                  >
                                    <span style={{ cursor: "default" }}>
                                      {/* Uploaded File: */}
                                    </span>
                                    <a
                                      href={field?.db_value?.document_fullpath}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        flex: "1 1 auto", // Makes the link grow as needed while keeping space for other elements
                                        marginBottom: "10px",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                        color: "blue",
                                        marginLeft: "5px",
                                        whiteSpace: "nowrap", // Prevents text wrapping for a cleaner look
                                        overflow: "hidden", // Ensures long text doesn’t break layout
                                        textOverflow: "ellipsis", // Adds ellipsis for overflow
                                      }}
                                      onMouseOver={(e) =>
                                        (e.currentTarget.style.textDecoration = "underline")
                                      }
                                      onMouseOut={(e) =>
                                        (e.currentTarget.style.textDecoration = "none")
                                      }
                                    >
                                      {field?.db_value?.document_name}
                                    </a>
                                


                                    <button
                                      className="btn btn-sm btn_green_yellow"
                                      onClick={() => toggleFileEditing(field?.param)}
                                      style={{
                                        marginRight: "24%",
                                        flexShrink: "0", // Prevents the button from shrinking
                                        width: "auto", // Keeps button size consistent
                                      }}
                                    >
                                      {/* {(((field?.db_value?.document_name === "" ||
                                        field?.db_value?.document_fullpath === "" ||
                                        field?.db_value === "") &&
                                        isFileChoosen[field?.param])) ? (
                                        <i className="bi bi-check-lg"></i>
                                      ) : (
                                        <i
                                          className={
                                            isFileEditing[field?.param]
                                              ? "bi bi-check-lg"
                                              : "bi bi-pencil-square"
                                          }
                                        ></i>
                                      )} */}

<i className="bi bi-pencil-square"></i>

                                    </button>


                                  </div>
                                


                                  )}







{
 (isFileEditing[field?.param] || 
    (
      field?.db_value?.document_name === "" ||
      field?.db_value?.document_fullpath === "" ||
      field?.db_value === ""
    )
  ) 

&& (

<>
                                <Col lg={8} className="my-auto">
                                  <input
                                      key={inputKey}
                                    type="file"
                                    accept=".pdf"
                                    className="form-control"
                                    style={{height:"35px"}}
                                    ref={(el) =>
                                      (fileInputRefs.current[field.param] = el)
                                    }
                                    onChange={(e) =>
                                      handleFileChange(e, field?.param)
                                    }

                                    // disabled={
                                    //   (field?.db_value?.document_name === "" ||
                                    //   field?.db_value?.document_fullpath === "" ||
                                    //   field?.db_value === "") 
                                    //     ? false 
                                    //     : !isFileEditing[field?.param]
                                    // }
                                    
                                  />
                                  {fileErrors[field?.param] && (
                                    <div className="text-danger">
                                      {fileErrors[field?.param]}
                                    </div>
                                  )}
                                </Col>
                                <Col lg={4} className="my-auto">


              {/* {((field?.db_value?.document_name === "" ||
              field?.db_value?.document_fullpath === "" ||
              field?.db_value === "") && (!isFileChoosen[field?.param])) ? (
                ""
              ) : (
                <button
                  className="btn btn-sm btn_green_yellow"
                  onClick={() => toggleFileEditing(field?.param)}
                >
                  {(((field?.db_value?.document_name === "" ||
                  field?.db_value?.document_fullpath === "" ||
                  field?.db_value === "") && (isFileChoosen[field?.param]))) ? (
                    <i className="bi bi-check-lg"></i>
                  ) : (
                    <i
                      className={
                        isFileEditing[field?.param]
                          ? "bi bi-check-lg"
                          : "bi bi-pencil-square"
                      }
                    ></i>
                  )}
                </button>
              )} */}


{ 
//  (isFileChoosen[field?.param]) 
(
  // field?.db_value?.document_name !== "" ||
  // field?.db_value?.document_fullpath !== "" ||
  field?.db_value !== ""
)
&& (
  <>
<button
                  className="btn btn-sm btn_green_yellow"
                    // onClick={() => toggleFileEditing(field?.param)}
                    onClick={() => saveFile(field?.param)}
                >
               
                    <i className="bi bi-check-lg"></i>
              
                </button>
                </>
)}


            </Col>


</>
)}









          
                                      </Row>
                            </td>
                          </tr>
                        )}





                        {field.type !== "file" &&
                          field.type !== "select" &&
                          field.type !== "textarea" && (
                            <tr>
                              <td>{field?.label}</td>
                              <td className="w-25">
                                <Row>
                                  <Col lg={8}>
                                    <input
                                      type={field?.type}
                                      className="form-control"
                                      value={
                                        extraData[field?.param] ??
                                        field?.db_value
                                      }
                                      onChange={(e) =>
                                        handleExtraDataChange(e, field?.param)
                                      }
                                      disabled={
                                        !isExtraDataEditing[field?.param]
                                      }

                                      min={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? field?.db_value || new Date().toISOString().split("T")[0]
                                          : null
                                      }
                                      max={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? new Date(
                                              (field?.db_value
                                                ? new Date(field?.db_value)
                                                : new Date()
                                              ).getTime() + 7 * 24 * 60 * 60 * 1000
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          : null
                                      }

                                    />
                                  </Col>

                                  <Col lg={4} className="my-auto">
                                  <button
                                      className="btn btn-sm btn_green_yellow"
                                      title={
                                        isExtraDataEditing[field?.param]
                                          ? "Save"
                                          : "Edit"
                                      }
                                      onClick={() =>
                                        toggleExtraDataEditing(field?.param)
                                      }
                                    >
                                      <i
                                        className={
                                          isExtraDataEditing[field?.param]
                                            ? "bi bi-check-lg"
                                            : "bi bi-pencil-square"
                                        }
                                      ></i>
                                    </button> 


{/* 
{(extraData[field?.param] !== field?.db_value &&
                isExtraDataEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleExtraDataEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}


                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    ))
                  : ""

                // <tr>
                //   <td colSpan="2">
                //     <b>No field is present for the selected status.</b>
                //   </td>
                // </tr>
              }














































































































              {

                currentFields.length > 0
                  ? currentFields.map((field) => (
                      <React.Fragment key={field?.param}>







                        {field?.type === "select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) =>
                                      handleChangeAQATO_TeamMember(
                                        e,
                                        field?.param
                                      )
                                    }
                                    className="form-select"


                                    // disabled={
                                    //   !isTeamMemberEditing[field?.param]
                                    // }

                                
                                    disabled={
                                      field?.param !== "change_attc_team_member"
                                        ? !isTeamMemberEditing[field?.param]
                                        : false
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="0">
                                      Select Team Member
                                    </option>
                                    {field.options?.map((member) => (
                                      <option
                                        key={member.id}
                                        value={member.id}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {member.fullname}
                                      </option>
                                    ))}
                                  </select>
                                </Col>

                                <Col>

                                {(field?.param !== "change_attc_team_member") && (
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> 
                                )}


                                  {/* {(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}




{field.type === "textarea" && (
  <tr>
    <td>{field?.label}</td>
    <td className="w-25">
      <Row>
        <Col lg={8}>
          <textarea
            className="form-control"
            rows="3"
            value={BigTextAreaData[field?.param] ?? field?.db_value}
            onChange={(e) => handleBigTextAreaDataChange(e, field?.param)}
            disabled={!isBigTextAreaEditing[field?.param]}
          />
        </Col>
        <Col lg={4} className="my-auto">
          <button
            className="btn btn-sm btn_green_yellow"
            title={isBigTextAreaEditing[field?.param] ? "Save" : "Edit"}
            onClick={() => toggleBigTextAreaDataEditing(field?.param)}
          >
            <i
              className={
                isBigTextAreaEditing[field?.param]
                  ? "bi bi-check-lg"
                  : "bi bi-pencil-square"
              }
            ></i>
          </button>
        </Col>
      </Row>
    </td>
  </tr>
)}






{field?.type === "file" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>

                                
                                {(field?.db_value?.document_name !== "" &&
                                  field?.db_value?.document_fullpath !== "" && field?.db_value !== "" )&& (



                                    <div
                                    className="d-flex align-items-center"
                                    style={{
                                      width: "100%", // Ensures the parent div takes full width
                                      justifyContent: "space-between", // Adjusts spacing between child elements
                                      flexWrap: "wrap", // Ensures responsive layout for smaller screens
                                    }}
                                  >
                                    <span style={{ cursor: "default" }}>
                                      {/* Uploaded File: */}
                                    </span>
                                    <a
                                      href={field?.db_value?.document_fullpath}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        flex: "1 1 auto", // Makes the link grow as needed while keeping space for other elements
                                        marginBottom: "10px",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                        color: "blue",
                                        marginLeft: "5px",
                                        whiteSpace: "nowrap", // Prevents text wrapping for a cleaner look
                                        overflow: "hidden", // Ensures long text doesn’t break layout
                                        textOverflow: "ellipsis", // Adds ellipsis for overflow
                                      }}
                                      onMouseOver={(e) =>
                                        (e.currentTarget.style.textDecoration = "underline")
                                      }
                                      onMouseOut={(e) =>
                                        (e.currentTarget.style.textDecoration = "none")
                                      }
                                    >
                                      {field?.db_value?.document_name}
                                    </a>
                                


                                    <button
                                      className="btn btn-sm btn_green_yellow"
                                      onClick={() => toggleFileEditing(field?.param)}
                                      style={{
                                        marginRight: "24%",
                                        flexShrink: "0", // Prevents the button from shrinking
                                        width: "auto", // Keeps button size consistent
                                      }}
                                    >
                                      {/* {(((field?.db_value?.document_name === "" ||
                                        field?.db_value?.document_fullpath === "" ||
                                        field?.db_value === "") &&
                                        isFileChoosen[field?.param])) ? (
                                        <i className="bi bi-check-lg"></i>
                                      ) : (
                                        <i
                                          className={
                                            isFileEditing[field?.param]
                                              ? "bi bi-check-lg"
                                              : "bi bi-pencil-square"
                                          }
                                        ></i>
                                      )} */}

<i className="bi bi-pencil-square"></i>

                                    </button>


                                  </div>
                                


                                  )}







{
 (isFileEditing[field?.param] || 
    (
      field?.db_value?.document_name === "" ||
      field?.db_value?.document_fullpath === "" ||
      field?.db_value === ""
    )
  ) 

&& (

<>
                                <Col lg={8} className="my-auto">
                                  <input
                                      key={inputKey}
                                    type="file"
                                    accept=".pdf"
                                    className="form-control"
                                    style={{height:"35px"}}
                                    ref={(el) =>
                                      (fileInputRefs.current[field.param] = el)
                                    }
                                    onChange={(e) =>
                                      handleFileChange(e, field?.param)
                                    }

                                    // disabled={
                                    //   (field?.db_value?.document_name === "" ||
                                    //   field?.db_value?.document_fullpath === "" ||
                                    //   field?.db_value === "") 
                                    //     ? false 
                                    //     : !isFileEditing[field?.param]
                                    // }
                                    
                                  />
                                  {fileErrors[field?.param] && (
                                    <div className="text-danger">
                                      {fileErrors[field?.param]}
                                    </div>
                                  )}
                                </Col>
                                <Col lg={4} className="my-auto">


              {/* {((field?.db_value?.document_name === "" ||
              field?.db_value?.document_fullpath === "" ||
              field?.db_value === "") && (!isFileChoosen[field?.param])) ? (
                ""
              ) : (
                <button
                  className="btn btn-sm btn_green_yellow"
                  onClick={() => toggleFileEditing(field?.param)}
                >
                  {(((field?.db_value?.document_name === "" ||
                  field?.db_value?.document_fullpath === "" ||
                  field?.db_value === "") && (isFileChoosen[field?.param]))) ? (
                    <i className="bi bi-check-lg"></i>
                  ) : (
                    <i
                      className={
                        isFileEditing[field?.param]
                          ? "bi bi-check-lg"
                          : "bi bi-pencil-square"
                      }
                    ></i>
                  )}
                </button>
              )} */}


{
//  (isFileChoosen[field?.param]) 
(
  // field?.db_value?.document_name !== "" ||
  // field?.db_value?.document_fullpath !== "" ||
  field?.db_value !== ""
)
  && (
  <>
<button
                  className="btn btn-sm btn_green_yellow"
                    // onClick={() => toggleFileEditing(field?.param)}
                    onClick={() => saveFile(field?.param)}
                >
               
                    <i className="bi bi-check-lg"></i>
              
                </button>
                </>
)}


            </Col>


</>
)}









          
                                      </Row>
                            </td>
                          </tr>
                        )}






                        {field.type !== "file" &&
                          field.type !== "select" &&
                          field.type !== "textarea" && (
                            <tr>
                              <td>{field?.label}</td>
                              <td className="w-25">
                                <Row>
                                  <Col lg={8}>
                                    <input
                                      type={field?.type}
                                      className="form-control"
                                      value={
                                        extraData[field?.param] ??
                                        field?.db_value 
                                      }
                                      onChange={(e) =>
                                        handleExtraDataChange(e, field?.param)
                                      }

                                      // disabled={
                                      //   !isExtraDataEditing[field?.param]
                                      // }

                                      disabled={(IsTeamMemberSelected && field?.param === "change_unique_no") ? false : !isExtraDataEditing[field?.param]}

                                      min={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? field?.db_value || new Date().toISOString().split("T")[0]
                                          : null
                                      }
                                      max={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? new Date(
                                              (field?.db_value
                                                ? new Date(field?.db_value)
                                                : new Date()
                                              ).getTime() + 7 * 24 * 60 * 60 * 1000
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          : null
                                      }
                                    />
                                  </Col>

                                  <Col lg={4} className="my-auto">


                                  {/* {
  field?.param === "change_unique_no" && IsTicketNumberEntered
    ? (
        <button
          className="btn btn-sm btn_green_yellow"
          title={isExtraDataEditing[field?.param] ? "Save" : "Edit"}
          onClick={() => saveTicket(field?.param)}
        >
          <i className="bi bi-check-lg"></i>
        </button>
      )
    : field?.param === "change_unique_no" && !IsTicketNumberEntered
    ? (
        console.log(""), null 
      )
    : (
        <button
          className="btn btn-sm btn_green_yellow"
          title={isExtraDataEditing[field?.param] ? "Save" : "Edit"}
          onClick={() => toggleExtraDataEditing(field?.param)}
        >
          <i
            className={
              isExtraDataEditing[field?.param] ? "bi bi-check-lg" : "bi bi-pencil-square"
            }
          ></i>
        </button>
      )
} 
 
 */}




{
  field?.param === "change_unique_no" && IsTicketNumberEntered
    ? (
        <button
          className="btn btn-sm btn_green_yellow"
          title={isExtraDataEditing[field?.param] ? "Save" : "Edit"}
          onClick={() => saveTicket(field?.param)}
        >
          <i className="bi bi-check-lg"></i>
        </button>
      )
    : field?.param === "change_unique_no" && !IsTicketNumberEntered
    ? (
      <button
          className="btn btn-sm btn_green_yellow"
          title={isExtraDataEditing[field?.param] ? "Save" : "Edit"}
          onClick={() => saveTicket(field?.param)}
          disabled
        >
          <i className="bi bi-check-lg"></i>
        </button>
      )
    : (
        <button
          className="btn btn-sm btn_green_yellow"
          title={isExtraDataEditing[field?.param] ? "Save" : "Edit"}
          onClick={() => toggleExtraDataEditing(field?.param)}
        >
          <i
            className={
              isExtraDataEditing[field?.param] ? "bi bi-check-lg" : "bi bi-pencil-square"
            }
          ></i>
        </button>
      )
} 






                    
              {/* {(extraData[field?.param] !== field?.db_value &&
                isExtraDataEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleExtraDataEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          )}

                      </React.Fragment>
                    ))
                  : ""

                // <tr>
                //   <td colSpan="2">
                //     <b>No field is present for the selected status.</b>
                //   </td>
                // </tr>
              }









              <tr>
                <td colSpan={2} className="text-center">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="submit"
                      className="btn btn_yellow_green"
                      // onClick={handleUpdate}
                      // disabled={!selectedStatusValue}
                      disabled={!isSelectionMade}
                      onClick={handleCancelRequestShow}
                    >
                      Update
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>

      {showCancelRequestModal && selectedStatusValue && isSelectionMade && (
        <VerificationModal
          show={showCancelRequestModal}
          onClose={handleCancelRequestClose}
          onYes={handleUpdate}
          Title="Confirm Update!"
          Message={
            <>
              {/* Are you sure you want to update status to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                {selectedStatusValue}
              </span>
              &nbsp;this ? */}
              Are you sure you want to change the status ?
            </>
          }
        />
      )}
    </>
  );
};

export default Stage1;
